
import {AgentItemInterface} from '@/composables/agents/item'
import {Options, setup, Vue} from 'vue-class-component'
import {file} from '@/composables/file'
import DatePicker from '@/components/core/DatePicker.vue'
import {AxiosError} from 'axios'

enum ProfileTabNames {
  COMMON = 'common',
  PASSPORT = 'passport',
  PASSPORT_PHOTO = 'passport_photo',
  EMAIL = 'email',
}

type InvalidDataResponse = {
  errors: any;
};

@Options({
  props: {
    item: Object,
  },
  data() {
    return {
      images:[],
      editProfile:false,
      editEmail: false,
      mainPagePhoto: '',
      showMainPage: false,
      secondPagePhoto: '',
      passportPhoto:'',
      showSecondPage:false,
      validateErrors: Object
    }
  },
  watch:{
    tab(value) {
      if (value === this.tabNames.PASSPORT_PHOTO) {
        this.loadMainPage()
        this.loadSecondPage()
      }
    }
  },
  computed: {
    photo() : string {
      return this.passportPhoto
    },
    mainPage(): boolean {
      return this.showMainPage
    },
    secondPage(): boolean {
      return this.showSecondPage
    },
    innError() {
      return this.validateErrors['inn']?.join(',') ?? ''
    },
    lastnameError() {
      return this.validateErrors['lastname']?.join(',') ?? ''
    },
    firstNameError() {
      return this.validateErrors['firstname']?.join(',') ?? ''
    },
    middleNameError() {
      return this.validateErrors['middlename']?.join(',') ?? ''
    },
    numberError() {
      return this.validateErrors['number']?.join(',') ?? ''
    },
    serialError() {
      return this.validateErrors['number']?.join(',') ?? ''
    },
    emailError() {
      return this.validateErrors['email']?.join(',') ?? ''
    }
  },
  components:{
    DatePicker
  }
})
export default class Profile extends Vue {

  ctx = setup(() => {
    return {...file()}
  })

  item!: AgentItemInterface
  images: Array<string> = []
  mainPagePhoto?: boolean
  secondPagePhoto?: boolean
  editProfile?: boolean
  editEmail?: boolean
  tab= ProfileTabNames.COMMON
  tabNames= ProfileTabNames

  validateErrors = {}

  updatePassportDate(date:string) {
    this.item.entity.passport.date_birth = date
  }

  changeSex() {
    if (this.editProfile){
      this.item.entity.passport.male = !this.item.entity.passport.male
    }
  }

  async loadMainPage() {
    if (!this.mainPagePhoto && this.item.entity.passport.page1) {
      this.images.push(await this.ctx.getPassportBase64ById(this.item.entity.passport.page1,false))
      this.mainPagePhoto = true
    }
  }

  async loadSecondPage() {
    if (!this.secondPagePhoto &&this.item.entity.passport.page2 && this.item.entity.passport.page2) {
      this.images.push(await this.ctx.getPassportBase64ById(this.item.entity.passport.page2,false))
      this.secondPagePhoto = true
    }
  }

  async save() {
    this.validateErrors = {}
    try {
      await this.item.savePassport()
    } catch (err: any) {
      if (err.isAxiosError) {
        this.validateErrors = (err.response?.data as InvalidDataResponse).errors
      }
      return
    }
    this.editProfile = false
  }

  async saveEmail() {
    this.validateErrors = {}
    try {
      await this.item.saveEmail()
    } catch (err: any) {
      if (err.isAxiosError) {
        this.validateErrors = (err.response?.data as InvalidDataResponse).errors
      }
      return
    }
    this.editEmail = false
  }

}
